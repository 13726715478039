import { getDefaultOptions } from 'API/auth'
import { getUrl } from 'API/getUrl'
import { handleApi } from 'API/handleApiCall'
import axios from 'axios'
import { Form } from 'components/Form/Form'
import { navigate, PageProps } from 'gatsby'
import { useEditableHook } from 'hooks/useEditableHook'
import React from 'react'
import { CustomModal } from '../../../../../components/common/useModal'
import { toast } from 'react-toastify'

const resourceDates = [
	{ text: 'Visible From:', name: 'visible_from' },
	{ text: 'Start Time:', name: 'available_from' },
	{ text: 'Available Till:', name: 'available_till' },
]

const Resource: React.FC<PageProps> = ({ params: { id: course }, location }) => {
	let ref = React.useRef<HTMLButtonElement | null>(null)

	const { edit, elem_id } = useEditableHook({ location })
	const [
		editable,
		// setEditable
	] = React.useState<any>({})
	React.useEffect(() => {
		if (edit === 'true' && elem_id !== '') {
			// const run = async () => {
			// 	const asset = await getLecture(elem_id, 'Teacher')
			// 	if (asset) {
			// 		console.log('asset', asset)
			// 		setEditable(asset)
			// 	}
			// }
			// run()
		}
	}, [edit, elem_id])
	return (
		<>
			<CustomModal
				header='Add Module'
				onClick={() => {
					if (edit === 'true') {
						toast('ToDo', { type: 'info' })
						return
					}
					console.log(ref)
					if (ref.current) {
						ref.current.click()
					}
				}}
			>
				<Form
					editableState={editable}
					getRef={(_ref) => {
						if (_ref) ref = _ref
					}}
					action={async ({ state }) => {
						if (edit === 'true') {
							if (edit === 'true') {
								toast('ToDo', { type: 'info' })
								return
							}
						}
						const module = await handleApi<{ uid: string }>(
							axios.post(
								getUrl('module/'),
								{
									...state,
									course,
								},
								getDefaultOptions()
							)
						)
						if (module) navigate(`/course/${course}/activities`)
					}}
					text={'Add Module'}
					fields={[
						{
							type: 'text',
							label: 'Name',
							name: 'name',
						},
						{
							type: 'textarea',
							label: 'Description',
							name: 'description',
						},
						{
							type: 'number',
							label: 'Index',
							name: 'index',
						},
						...resourceDates.map((elem) => ({
							type: 'datetime-local',
							label: elem.text,
							name: elem.name,
						})),
					]}
				></Form>
			</CustomModal>
		</>
	)
}

export default Resource
